<template>
  <div class="cs_notice">
    <div
      class="box"
      v-for="(list, idx) in noticeArray"
      :key="`n_${list.seq}`"
      :style="
        idx >= parseInt(noticeArray.length - 1)
          ? 'border-bottom: 1px solid #000'
          : ''
      "
    >
      <div class="top">
        <div class="left">
          <img src="@/assets/images/sub/notice_new.png" alt="" />
        </div>
        <div class="right" @click="openContent($event)">{{ list.subject }}</div>
      </div>
      <div class="bottom">
        <div class="left">램 관리자</div>
        <div class="center">{{ day(list.r_date) }}</div>
        <div class="right">
          <router-link to="" @click.native="openContent($event)"
            ><img src="@/assets/images/sub/my_down_ico.png" alt=""
          /></router-link>
        </div>
      </div>
      <div class="text" style="display: none" v-html="list.contents">
        {{ list.contents }}
      </div>
    </div>
    <template v-if="listArray.length > 0">
      <div class="box" v-for="(list, index) in listArray" :key="list.seq">
        <div class="top">
          <div class="left">{{ parseInt(listArray.length) - index }}</div>
          <div class="right" @click="openContent($event)">
            {{ list.subject }}
          </div>
        </div>
        <div class="bottom">
          <div class="left">램 관리자</div>
          <div class="center">{{ day(list.r_date) }}</div>
          <div class="right">
            <router-link to="" @click.native="openContent($event)"
              ><img src="@/assets/images/sub/my_down_ico.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="text" style="display: none" v-html="list.contents">
          {{ list.contents }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="box nodata">
        <p>공지사항이 없습니다.</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      category: parseInt(this.$route.params.category) || "카테고리 일반",
    };
  },
  mounted() {
    this.$store.dispatch("cs/dataClear");
    this.getList();
  },
  computed: {
    ...mapState("cs", [
      "result",
      "msg",
      "limit",
      "totalPage",
      "listArray",
      "noticeArray",
    ]),
  },
  methods: {
    openContent(e) {
      const textEl = e.target.closest(".box").querySelector(".text");
      const imgEl = e.target
        .closest(".box")
        .querySelector(".bottom")
        .querySelector("img");

      const upImg = require("@/assets/images/sub/my_up_ico.png");
      const downImg = require("@/assets/images/sub/my_down_ico.png");
      [...document.querySelectorAll(".box > .text")].map((text) => {
        if (textEl !== text) {
          text.style.display = "none";
        }
      });
      [...document.querySelectorAll(".bottom")].map((bottom) => {
        let img = bottom.querySelector("img");
        if (imgEl !== img) {
          img.src = downImg;
        }
      });
      if (textEl.style.display === "none") {
        imgEl.src = upImg;
        textEl.style.display = "block";
      } else {
        imgEl.src = downImg;
        textEl.style.display = "none";
      }
    },
    day(str) {
      return this.$moment(str).format("YY-MM-DD");
    },
    async getList(reset = false) {
      if (reset) {
        this.page = 0;
      }
      await this.$store.dispatch("cs/getList", {
        category: this.category,
        page: this.page,
        id: "notice",
        reset,
      });
      this.page++;
      this.$emit("moreCheck", this.totalPage > this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  &.nodata {
    text-align: center;
    padding: 15px 0;
  }
}
</style>
